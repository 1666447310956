/* eslint-disable arrow-body-style */
import { postFormDataMethod, postMethod } from "../apiConfig";

export const getProfileApi = (data) => {
    return postMethod('/profile/get-profile-details', data);
};

export const updateProfileApi = (data) => {
    return postFormDataMethod('/profile/update-profile', data);
}
/* eslint-disable arrow-body-style */
